<template>
  <bg-modal title="Ubah Jumlah Ditransfer" :value="isOpen" @close="closeModal">
    <ValidationObserver ref="observer">
      <div class="mb-32">
        <bg-text size="body-1" class="mb-8">
          Jumlah Distransfer{{ isEdit ? ' lama' : '' }}
        </bg-text>
        <bg-text v-if="!isEdit" size="body-4" class="mb-16 color-grey">
          Nominal sesuai model kerja sama
        </bg-text>
        <bg-text size="body-2">Rp {{ originalAmount | rupiah }}</bg-text>
      </div>

      <ValidationProvider
        name="Perubahan Jumlah Ditransfer"
        rules="required|numeric|min_value:1"
        v-slot="{ errors }"
      >
        <bg-field
          :label="`${
            isEdit ? 'Jumlah Ditransfer Baru' : 'Perubahan Jumlah Ditransfer'
          }`"
          :error="!!errors.length"
          :message="errors[0]"
        >
          <bg-input
            placeholder="Masukkan jumlah ditransfer"
            v-model="amount"
            prefix="Rp"
            data-testid="perubahan-jumlah-transfer"
          />
        </bg-field>
      </ValidationProvider>

      <ValidationProvider
        name="Alasan"
        rules="required|max:200"
        v-slot="{ errors }"
      >
        <bg-field
          label="Alasan Ubah Jumlah Transfer"
          :error="!!errors.length"
          :message="errors[0]"
        >
          <bg-textarea
            v-model="reason"
            placeholder="Masukkan alasan"
            :has-counter="false"
            :max-char="200"
            data-testid="alasan-perubahan"
          />
        </bg-field>
      </ValidationProvider>
    </ValidationObserver>

    <template #footer>
      <div class="button-modal">
        <bg-button
          class="mr-16"
          :disabled="isButtonActionDisabled"
          data-testid="reset-perubahan"
          @click="handleResetTransferSum"
        >
          {{
            isEdit
              ? 'Gunakan Jumlah Ditransfer Lama'
              : 'Reset Jumlah Ditransfer'
          }}
        </bg-button>
        <bg-button
          :disabled="isButtonActionDisabled"
          variant="primary"
          @click="handleSave"
          data-testid="simpan-perubahan"
        >
          Simpan
        </bg-button>
      </div>
    </template>
  </bg-modal>
</template>

<script>
import {
  BgText,
  BgInput,
  BgModal,
  BgTextarea,
  BgButton,
  BgField,
} from 'bangul-vue';
import { currencyFormatter } from 'Utils/formatter';

export default {
  name: 'EditTotalTransferModal',

  components: {
    BgText,
    BgInput,
    BgModal,
    BgTextarea,
    BgButton,
    BgField,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    originalAmount: {
      type: Number,
      required: true,
    },
    originalReason: {
      type: String,
      default: '',
    },
    amountReason: {
      type: Object,
      required: true,
    },
    updated: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      Default: false,
    },
  },

  filters: {
    rupiah: currencyFormatter,
  },

  data() {
    return {
      amount: null,
      reason: '',
    };
  },

  watch: {
    amountReason: {
      immediate: true,
      deep: true,
      handler(value) {
        if (this.updated) {
          this.amount = value.disbursed_amount || null;
          this.reason = value.reason_amount_changed;
        }
      },
    },
  },

  computed: {
    isButtonActionDisabled() {
      return !this.amount || !this.reason;
    },
  },

  methods: {
    closeModal() {
      this.$emit('update:isOpen', false);
    },

    handleResetTransferSum() {
      this.$emit('update:updated', false);
      this.$emit('update:amountReason', {
        ...this.amountReason,
        disbursed_amount: this.originalAmount,
        reason_amount_changed: null,
      });
      this.amount = null;
      this.reason = '';

      this.closeModal();
    },

    async handleSave() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.$emit('update:updated', true);
        this.$emit('update:amountReason', {
          ...this.amountReason,
          disbursed_amount: this.amount,
          reason_amount_changed: this.reason,
        });

        this.closeModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-modal {
  display: flex;
  justify-content: right;
}
</style>
